.input {
    border-image-source: linear-gradient(to right, #00F5FB, #DC04CB);
    border-width: 0 0 2px 0;
    border-image-slice: 1;
    border-radius:  20px 20px 0 0;
    background-color: transparent;
  }
  
  .input:focus, .input:active {
    outline: none; /* remove the default focus/active outline */
    outline-color: white; /* set the outline color to white */
    border-image-source: linear-gradient(to right, #00F5FB, #DC04CB);
    border-width: 0 0 2px 0;
    border-image-slice: 1;
    border-radius: 30px;
  }
  
  .checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background-color: transparent;
    width: 20px;
    height: 20px;
    position: relative;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    background: linear-gradient(to right, #00F5FB, #DC04CB);
  }
  
 
  
  .checkbox::before {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    border-radius: 50%;
    background: white;
  }

  .checkbox:checked::after{
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    border-radius: 50%;
    background: linear-gradient(to right, #00F5FB, #DC04CB);
  }