
  
  .Loadingitem {
    margin-top: 60px;
    width: 56%;
    overflow: visible;
    background-color: transparent;
    stroke: #fff;
    stroke-width: 2;
    stroke-linejoin: round;
    stroke-linecap: round;
  }

  .styles_typicalWrapper__1_Uvh::after{
    content: '' !important;
  }