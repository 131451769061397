.MainPartnersContainer{
    width: 100%;
    height: 290px;
    background: linear-gradient(to right, #00F5FB, #3FA9F5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.MainPartnersContainer h3{
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    color: white;
}

.PartnerCartBG{
    background-color: white;
    width: 248px;
    height: 112px;
    border-radius: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
}